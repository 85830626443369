@import './mixin';

.registerForm {
    .container {
        padding: 0;
    }
    .registerButton {
        width:100%;
    }
    
    .registerBar {
        margin-bottom: 5rem;

        .btn {
            min-width: 12rem;
            max-width: 12rem;
        }
    }
    i {
        font-size: 2rem;
        color: #333;
    }

    .accounts {
        margin: 15px auto;
        width: 720px;
        @include clearfix;
      }
      
      .account {
        float: left;
        width: 240px;
        margin: 10px 0;
        padding: 20px;
        text-align: center;
        background: #eaeceb;
        background-clip: padding-box;
        border: solid #599138;
        border-width: 2px 0 2px 2px;
        @include box-sizing(border-box);
        @include box-shadow(0 1px 2px rgba(black, .1));
        position: relative;
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      
        &:last-child {
          border-width: 2px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      .accountShadow {
        position: absolute;
        background-color: #536a5e;
        left: -20px;
        right: 20px;
        top: 10px;
        bottom: 10px;
        z-index: -1;
    }  
      .account-title {
        position: relative;
        margin: -20px -10px 20px;
        padding: 20px;
        line-height: 1;
        font-size: 16px;
        font-weight: bold;
        color: #43810c;
        // border-bottom: 1px dashed #d2d2d2;
      
        &:before {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 1px;
          background-size: 3px 1px;
          @include linear-gradient(left, white, white 33%, #d2d2d2 34%, #d2d2d2);
        }
      }
      
      .accountImg {
        margin: 0 auto 20px;
        width: 90px;
        height: 90px;
        line-height: 90px;
        font-size: 19px;
        font-weight: bold;
        color: white;
        background: #fff;
        border-radius: 45px;

        .agent {
          width: 9rem;
          padding: 0.5rem;
         background: #f6f6f6;
        }

        .company {
          padding: 0.5rem;
          width: 9rem;
        }
      
        > span {
          font-size: 12px;
          font-weight: normal;
          color: rgba(white, .9);
        }

        img {
            width: 10rem;
        }
      }
      
      .account-features {
        margin-bottom: 25px;
        line-height: 2;
        font-size: 12px;
        color: #999;
        text-align: center;
        list-style-type: none;
        min-height: 80px;
        padding-left: 0;
        
        > li > strong {
          font-weight: bold;
          color: #888;
        }
      }
      
      .account-button {
        display: inline-block;
        vertical-align: top;
        padding: 0 15px;
        line-height: 30px;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        background: #7c69a3;
        &.agent {
            background: #ef633b
        }
        &.individual {
            background-color: #112d19;
        }
        &.company {
            background-color: #66650f;
        }
        &:active {
          color: rgba(white, .95);
          background: #6f5e96;
          border-color: black #222 #222;
          @include linear-gradient(top, #6f5e96, #8770b9);
          @include box-shadow(inset 0 1px 2px rgba(black, .25));
        }
      }
      
      .account-tall {
        margin: 0;
        background-color: white;
        border-width: 2px;
        border-radius: 4px;
        box-shadow: 20px 0 10px -10px rgba(0, 0, 0, .15), -20px 0 10px -10px rgba(0, 0, 0, .15);    
        width:240px;
      
        > .account-title { font-size: 18px; }
      
        > .accountImg {
          width: 100px;
          height: 100px;
          line-height: 100px;
          font-size: 21px;
          border-radius: 50px;

        }
      
        > .account-features { font-size: 13px; }
      
        > .account-button {
          padding: 0 16px;
          line-height: 32px;
        }
      
        + .account { border-left: 0; }
      }
      

}