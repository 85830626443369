.homeHeader {
    margin-bottom: 1rem;
    background-color: #ffffff;
    .flagBanner {
        width: 100%;
    }
    .flag {
        height:.4rem;

        &.black {
            background-color: black;
        }

        &.yellow {
            background-color: gold;
        }

        &.red {
            background-color: red;
        }
    }

    .homeBanner {
        margin-top: 2rem;
    }

    .topMenu {
        display: flex;
        justify-content: center;
        flex-flow: row;

        .title {
            p { 
                font-size: 3.5rem;
                margin-top: 1.5rem;
                margin-left: 1.5rem;
                text-transform: capitalize;
            }
        }

        .logoImage {
            width: 6rem;
            align-self: center;
        }

    }
}