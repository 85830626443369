
.homeNav {
    display: inline;
    .homeNavHeader {
        // box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    }
    .homeNavCollapse {
        // box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        a {
            color: #ffffff;
       }
    }
    .container-fluid {
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        background-color: #173302;
        // margin-bottom: 2.0rem;
    }

    .homeBrand {
        display: flex;

        .brandName {
            text-transform: capitalize;
            font-size: 1.9rem;
            color: #ffffff;
        }
        
        .logoImage {
            display: block;
            width: 3rem;
        }
    }
    @media (min-width: 768px){
        .container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
            margin-right: 0;
            margin-left: 0;
            margin: 0 9rem !important;
        }
    }

}