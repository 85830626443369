.registerPage {
    .registerSection {
        min-height: 400px;
        min-width: 320px;
        background: #fff;
        margin-right: 20px;
        margin-top: 10px;
        box-shadow: 0px 0px 10px rgba(0,0,0,.3);
        padding: 20px 30px;
    }

    .pageCrumb {
        margin-bottom: 2rem;
        border-bottom: 1px solid #eee;
        padding-bottom: 1rem;

        .prevPage {
            text-decoration: underline;
            padding-right: 0.3rem;
        }

        .currentPage {
            padding-left: 0.7rem;
            color: #666666;
        }
    }
    
    .pageName {
        color: #b2b2b2;
        font-weight: 700;
        font-size: 1.6rem;
    }
}