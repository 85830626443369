.loginButton {
    width:100%;
    margin-top:2rem;
    background: #173302;
    color: #ffffff;
    border: none;
    &:hover {
        background: #173302;
    }
}

a{
    text-decoration: none;
    color:#333;
}

a:hover{
    color:#333;
}

.helpSection {
    margin-top:3rem;
    margin-bottom:3rem;
}