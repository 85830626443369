.homeFooter {
    background-color: #173302;
    position: relative;
    padding: 20px 0;
    a {
        color : #dddddd !important;
    }
    .sectionTitle{
        text-transform: uppercase;
        font-size: 1.4rem;        
    }
    .sectionAbout {
        display: flex;
        flex-direction: column;
        min-height: 181px;
        padding:10px 0;
        color: #dddddd;
        border-right: 0.1rem solid #888888;

        ul {
            list-style-position: inside;
            padding-left: 0;
            list-style-type: none;
        }

        .socialBtn {
            margin-top:1.7rem;
            display: flex;
            flex-direction: row;
            svg {
                padding-right:1rem;
                font-size: 2.0rem;
            }
            img{
                cursor: pointer;
                padding-right:1rem;
                width: 4.0rem;            
            }
        }
    }

    .middleSection {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color:#dddddd;
        padding:10px 0;
        border-right: 0.1rem solid #888888;

        ul {
            list-style-position: inside;
            padding-left: 0;
            list-style-type: none;
        }
    }
}