.homePage {
    .loginSection {
        min-height: 400px;
        min-width: 320px;
        background: rgb(238, 238, 238);
        margin-right: 20px;
        margin-top: 10px;
        box-shadow: 0px 0px 10px rgba(0,0,0,.3);
        padding: 20px 30px;
    }

    .bannerContainer {
        position: relative;
        .bannerTitle {
            // color: #ffffff;
            // font-size: 1.6rem;
            // text-shadow: 1px 1px 2px #000;
            color: #b2b2b2;
            font-weight: 700;
            font-size: 1.6rem;
        }
        .bannerCover {
            position: absolute;
            top: 0;
            left: 0;
            right: -60px;
            bottom: 0;
            background: #c2cac091;

            .bannerTitle {
                position: absolute;
                left: 10%;
                top: 40%;
                color: #b2b2b2;
                font-size: 3rem;
                font-weight: 500;
                // text-shadow: 2px 2px 4px #2a3e06;
            }
        }
    }

    .mainContent {
        margin-bottom:2rem;
    }

}