.sidebar .logo a.logo-mini img {
    width: 50px;
    margin-left: -3px;
    display: block;
    margin-top: 2px;
    border-radius: 5rem;
}

.sidebar:after, body>.navbar-collapse:after {
    // background: linear-gradient(180deg,#173302 0,#444);
}

.sidebar .logo .simple-text, body > .navbar-collapse .logo .simple-text {
    text-transform: capitalize;
    padding: 5px 0px;
    display: block;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 400;
    line-height: 25px;

    .idHolder{
        font-size: 1.37rem;
    }

    &.logo-normal{
        line-height: 2.5;
    }
}

.sidebar .sidebar-wrapper {
    position: relative;
    // height: calc(100vh - 75px);
    overflow: auto;
    width: 260px;
    z-index: 4;
    padding-bottom: 30px;
}

.sidebar .nav > li.active-pro {
    position: relative;
    width: 100%;
    bottom: 10px;
}

//Maritime customization
.sidebar .nav {
    margin-top: 2px;
}

.sidebar .nav li > a {
    color: #ffffff;
    background-color: #00000075;
    margin:2px 0px;
    opacity: .86;
    border-radius: 0px;
    &:hover{
        background-color: #00000055;
        color: #000000;
    }
}
.sidebar .nav li.active > a {
    color: #FFFFFF;
    opacity: 1;
    background: rgb(0, 0, 0);
}