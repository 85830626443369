@mixin box-sizing($val) {
    -webkit-box-sizing: $val;
       -moz-box-sizing: $val;
            box-sizing: $val;
  }
  
  @mixin user-select($val...) {
    -webkit-user-select: $val;
       -moz-user-select: $val;
        -ms-user-select: $val;
            user-select: $val;
  }
  
  @mixin box-shadow($shadows...) {
    -webkit-box-shadow: $shadows;
            box-shadow: $shadows;
  }
  
  @function inverse-side($side) {
    @if      $side == top    { @return bottom; }
    @else if $side == bottom { @return top; }
    @else if $side == left   { @return right; }
    @else if $side == right  { @return left; }
  }
  
  @mixin linear-gradient($gradientLine, $colorStops...) {
    background-image: -webkit-linear-gradient($gradientLine, $colorStops);
    background-image:    -moz-linear-gradient($gradientLine, $colorStops);
    background-image:      -o-linear-gradient($gradientLine, $colorStops);
    @if length($gradientLine) == 2 {
    background-image:         linear-gradient(to #{inverse-side(nth($gradientLine, 1))} #{inverse-side(nth($gradientLine, 2))}, $colorStops);
    } @else {
    background-image:         linear-gradient(to #{inverse-side($gradientLine)}, $colorStops);
    }
  }
  
  @mixin radial-gradient($gradient...) {
    background-image: -webkit-radial-gradient($gradient);
    background-image:    -moz-radial-gradient($gradient);
    background-image:      -o-radial-gradient($gradient);
    background-image:         radial-gradient($gradient);
  }
  
  @mixin transition($transition...) {
    -webkit-transition: $transition;
       -moz-transition: $transition;
         -o-transition: $transition;
            transition: $transition;
  }
  
  @mixin transition-property($properties...) {
    -webkit-transition-property: $properties;
       -moz-transition-property: $properties;
         -o-transition-property: $properties;
            transition-property: $properties;
  }
  
  @mixin transform($transform...) {
    -webkit-transform: $transform;
       -moz-transform: $transform;
        -ms-transform: $transform;
         -o-transform: $transform;
            transform: $transform;
  }
  
  @mixin clearfix {
    zoom: 1;
  
    &:before, &:after {
      content: '';
      display: table;
    }
  
    &:after { clear: both; }
  }
  
  @mixin inline-block {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
  }
  
  @mixin hide-text() {
    font: 0/0 serif;
    text-shadow: none;
    color: transparent;
  }
  
  @mixin hidpi {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
           only screen and (min-resolution: 144dpi) {
      @content;
    }
  }
  