body, .wrapper {
    height: auto !important;
}

.adminPage.wrapper {
    background-color: #cff6d1;
    min-height: 100vh !important;
    height: auto !important;
    
    .mainBody {
        position: relative;
        // margin: auto 10rem;
        margin-top: -10px;
        background-color: #f7f7f8;;
    }
    
    .main-panel{
        background-color: #f7f7f8;
    }
    .sideBar {
        width: 260px;
        display: block;
        z-index: 1;
        color: #fff;
        font-weight: 200;
        background-size: cover;
        background-position: center center;
        margin-left: -15px; //maritime
    }

    // .sidebar {
    //     position: absolute;
    // }
    .sidebar {
        position: relative;
        float: left;
    }
    
    .sidebar .logo {
        background-color: #173302;
        padding: 10px 30px;
    }
    .sidebar .sidebar-wrapper {
        width: 260px;
        z-index: 4;
        padding-bottom: 30px;
        //background: #173302;
        /* background: linear-gradient(180deg, rgba(23,51,2,1) 0%, rgba(23,51,0,1) 25%, rgba(255,255,255,1) 100%); */
        //background: linear-gradient(180deg, rgba(23,51,2,1) 0%, rgba(23,51,2,1) 35%, rgba(221,221,221,1) 100%);
        background:#f7f7f8;
    }

    .sidebar .logo a.logo-mini {
        float: left;
        text-align: center;
        width: 30px;
        margin-right: 25px;
    }

    // .sidebar .nav>li.active-pro a {
    //     background: hsla(0, 0%, 100%, 0.24);
    //     opacity: 1;
    //     color: #304b22;
    // }

    .navbar-default {
        background-color: transparent;
    }
    .card {
        border-radius: 1px !important;
    }
}

.labelBullet {
    padding: 5px;
    margin-top: 10px;
    margin-right: 2px;
    height: 1px;
    border-radius: 45px;
}

glabel {
    padding-right: 4px;;
}

.card .footer .legend {
    padding: 5px 0;
   display: flex;
}