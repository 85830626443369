.alertContainer {
    margin-left: 2px;

    .alert {
        margin-bottom: 5px;
    }

    .alert-info {
        background-color: #f7f7f8;
        color: #173302;
    }
}